import { useState, useEffect } from "react";
import axios from "axios";

export default function useItemData({ collection, itemId }) {
  const [data, set_data] = useState();

  useEffect(() => {
    axios
      .get(
        `${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/items/${collection}/${itemId}?fields=*,image.*`
      )
      .then((res) => {
        set_data(res?.data?.data);
      });
  }, []);

  return data;
}
