import { useEffect, useState } from "react";
import arrayShuffle from "array-shuffle";

import Cycler from "features/Screens/Lobby/Cycler";
import Message from "features/Screens/Lobby/Widgets/Message";
import Event from "features/Screens/Lobby/Widgets/Event";
import Poster from "./Widgets/Poster";
import ScreenConstants from "../ScreenConstants";

export default function Lobby({ screenData, news, did_you_know }) {
  const [items, set_items] = useState([]);

  useEffect(() => {
    let new_items = [];

    if (screenData?.messages?.length > 0) {
      new_items = new_items.concat(
        screenData?.messages?.map((message) => ({
          component: Message,
          data: { ...message, id: "message-" + message.id },
        }))
      );
    }

    if (screenData?.posters?.length > 0) {
      new_items = new_items.concat(
        screenData?.posters?.map((poster) => ({
          component: Poster,
          data: { ...poster.lobby_posters_id, id: "poster-" + poster.id },
          size: 2,
        }))
      );
    }

    if (screenData?.events?.length > 0) {
      new_items = new_items.concat(
        screenData?.events?.map((event) => ({
          component: Event,
          data: { ...event.events_id, id: "event-" + event.id },
          size: 2,
        }))
      );
    }

    new_items = arrayShuffle(new_items);
    set_items(new_items);
  }, [screenData, news, did_you_know]);

  return (
    <>
      <div
        style={{
          position: "relative",
          height: ScreenConstants.lobby.height,
          width: ScreenConstants.lobby.width,
          zIndex: 2,
          overflow: "hidden",
        }}
      >
        {items.length > 0 && <Cycler items={items} />}
      </div>
    </>
  );
}
