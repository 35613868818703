import { useEffect } from "react";
import { useMqtt } from "./MqttProvider";
import { useStore } from "./StoreProvider";
import useUrlParams from "utils/useUrlParams";

export default function CycleSpeedSub() {
  const { subscribe, unsubscribe } = useMqtt();
  const { set_cycleSpeed } = useStore();
  const { test, uid } = useUrlParams();

  useEffect(() => {
    if (!test) return;

    const subId = subscribe(`pn1/wfactory-nodejs/set/cycleSpeed/${uid}`, (topic, message) => {
      let speed = parseInt(message);
      set_cycleSpeed(isNaN(speed) || speed < 2000 ? 20000 : speed);
    });

    return () => {
      unsubscribe(subId);
    };
  }, [subscribe, unsubscribe, set_cycleSpeed, test, uid]);

  return null;
}
