import arrayShuffle from "array-shuffle";
import { useEffect, useState } from "react";

import deepMerge from "utils/deepMerge";
import useUrlParams from "utils/useUrlParams";
import useWidgetData from "utils/useWidgetData";
import ScreenConstants from "../ScreenConstants";
import Cycler from "./Cycler";
import DidYouKnow from "./Widgets/DidYouKnow";
import Event from "./Widgets/Event";
import LargeMessage from "./Widgets/LargeMessage";
import Logo from "./Widgets/Logo";
import Message from "./Widgets/Message";
import Poster from "./Widgets/Poster";

function useDraftAndPublished(collection) {
  const { filters, test } = useUrlParams();

  const [data, set_data] = useState();
  const data_published = useWidgetData({ collection });
  const data_draft = useWidgetData({ collection, draft: true });

  useEffect(() => {
    if (test) {
      if (filters?.includes("published") && filters?.includes("draft")) {
        set_data(deepMerge(data_draft, data_published));
      } else if (filters?.includes("published")) {
        set_data(data_published);
      } else if (filters?.includes("draft")) {
        set_data(data_draft);
      }
    } else {
      // Default to published items
      set_data(data_published);
    }
  }, [data_published, data_draft, filters, test]);

  return data;
}

export default function ElevatorCabs({ screenData }) {
  const [items, set_items] = useState([]);

  const did_you_know = useDraftAndPublished("did_you_know");

  useEffect(() => {
    let new_items = [];

    if (did_you_know?.messages) {
      new_items = new_items.concat(
        did_you_know?.messages?.map((dyk, dykIndex) => ({
          component: DidYouKnow,
          data: { ...dyk, id: "dyk-" + dykIndex },
          size: 1,
        }))
      );
    }

    if (screenData?.messages?.length > 0) {
      new_items = new_items.concat(
        screenData?.messages?.map((message) => {
          return {
            component: message?.large ? LargeMessage : Message,
            data: { ...message, id: "message-" + message?.id },
            size: message?.large ? 3 : 2,
          };
        })
      );
    }

    if (screenData?.events?.length > 0) {
      new_items = new_items.concat(
        screenData?.events?.map((event) => {
          return {
            component: Event,
            data: { ...event, id: "event-" + event.id },
            size: 3,
          };
        })
      );
    }

    if (screenData?.logos?.length > 0) {
      new_items = new_items.concat(
        screenData?.logos?.map((logo) => ({
          component: Logo,
          data: { ...logo, id: "logo-" + logo.id },
          size: 2,
        }))
      );
    }

    if (screenData?.posters?.length > 0) {
      new_items = new_items.concat(
        screenData?.posters?.map((poster) => ({
          component: Poster,
          data: { ...poster, id: "poster-" + poster.id },
          size: 3,
        }))
      );
    }

    new_items = arrayShuffle(new_items);
    set_items(new_items);
  }, [screenData, did_you_know]);

  return (
    <div
      style={{
        position: "relative",
        height: ScreenConstants.elevator_cabs.height,
        width: ScreenConstants.elevator_cabs.width,
        zIndex: 2,
      }}
    >
      <div id="container" style={{ position: "absolute", height: "100%", width: "100%", zIndex: 1 }} />
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          zIndex: 2,
        }}
      >
        {items.length > 0 && <Cycler items={items} />}
      </div>
    </div>
  );
}
