import { useEffect, useState } from "react";

export default function useUrlParams() {
  const [params, set_params] = useState({});
  useEffect(() => {
    if (!window.location) return;
    const urlParams = new URLSearchParams(window.location.search);
    let obj = {};
    for (var key of urlParams.keys()) {
      obj[key] = urlParams.getAll(key);
    }
    set_params(obj);
  }, []);

  return params;
}
