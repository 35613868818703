import { useEffect, useState } from "react";
import { useMqtt } from "utils/MqttProvider";

const COLORS = {
  morning: ["#FF642E", "#FF972E", "#3A7FC4", "#C38EF1"],
  lunch: ["#008571", "#FE9900", "#3751A6", "#D68FF5"],
  afternoon: ["#FF643B", "#FF9818", "#008673", "#D58FF5"],
  evening: ["#004FAE", "#FF991B", "#38396E", "#9A5190"],
};

function useTime() {
  const { subscribe, unsubscribe } = useMqtt();
  const [time, set_time] = useState(null);

  useEffect(() => {
    const subId = subscribe(`pn1/data-center/time`, (topic, message) => {
      const mqttData = JSON.parse(message);
      set_time(mqttData);
    });

    return () => {
      set_time(null);
      unsubscribe(subId);
    };
  }, [subscribe, unsubscribe]);

  return time;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function useRandomColor() {
  const [random_bg] = useState(() => getRandomInt(4));
  const time = useTime();
  return COLORS?.[time?.momentOfDay]?.[random_bg];
}
