import classes from "./DidYouKnow.module.scss";
import dayjs from "dayjs";

export default function DidYouKnow({ text, year }) {
  return (
    <div className={classes.DidYouKnow}>
      <div className={classes.Label}>Did you know?</div>
      <div className={classes.Date}>
        {dayjs().format("MMM DD")}, {year}
      </div>
      <div className={classes.Title}>{text}</div>
      <div className={classes.Source}>Source Wikipedia</div>
    </div>
  );
}
