import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import classes from "./Cycler.module.scss";
import { useStore } from "utils/StoreProvider";

const ANIMATION_DURATION = 0.85;
const NUM_ITEMS_IN_CYCLE = 4;

export default function Cycler({ items }) {
  const [index, set_index] = useState(0);
  const [isVisible, set_isVisible] = useState(() => true);
  const { cycleSpeed } = useStore();

  useEffect(() => {
    if (!items?.length) return;

    const timeout = setTimeout(() => {
      if ((index + 1) % NUM_ITEMS_IN_CYCLE === 0) {
        set_isVisible(false);
        setTimeout(
          () => {
            set_isVisible(true);
          },
          cycleSpeed ? cycleSpeed : 2 * 60 * 1000
        );
      }

      if (isVisible) {
        set_index((index) => (index + 1) % items.length);
      }
    }, cycleSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [index, items, isVisible, cycleSpeed]);

  if (!items.length > 0) return null;

  const Component = items?.[index]?.component;
  if (!Component) return "FAIL";

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", height: "100%", padding: 200, boxSizing: "border-box" }}>
        <AnimatePresence>
          {isVisible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: ANIMATION_DURATION }}
              className={classes.Box}
            >
              <motion.div
                key={items[index].data.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: ANIMATION_DURATION }}
                style={{ width: "100%", height: "100%" }}
              >
                {Component && <Component {...items[index].data} />}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
