import classes from "./Poster.module.scss";

import ScreenConstants from "features/Screens/ScreenConstants";

export default function Poster({ id, title, image }) {
  const { width, height } = ScreenConstants.elevator_cabs;

  return (
    <div className={classes.Poster} style={{ width, height }}>
      <img
        alt={title}
        className={classes.Image}
        src={`${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/assets/${image?.id}`}
      />
    </div>
  );
}
