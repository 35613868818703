import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMqtt } from "utils/MqttProvider";
import { useStore } from "utils/StoreProvider";
import classes from "./Filters.module.scss";

export default function Filters() {
  const { filters, set_filters, uid } = useStore();
  const { collection, itemId } = useParams();
  const { publish } = useMqtt();

  const [selectedButton, set_selectedButton] = useState("slow");

  const setCycleSpeed = (speed) => {
    publish(`pn1/wfactory-nodejs/set/cycleSpeed/${uid}`, speed.toString());
  };

  return (
    <div className={classes.Filters}>
      <div className={classes.OptionsTitle}>Options</div>
      <div>
        <label style={{ display: "flex" }}>
          <input
            style={{ marginRight: 10 }}
            type="checkbox"
            value="published"
            checked={filters.published}
            onChange={(e) => {
              set_filters({ ...filters, published: e.target.checked });
            }}
          />
          <div>
            <b>Published</b>
            <p style={{ marginTop: 5, fontSize: 13 }}>Show Published Content</p>
          </div>
        </label>
      </div>
      <div>
        <label style={{ display: "flex" }}>
          <input
            style={{ marginRight: 10 }}
            type="checkbox"
            value="draft"
            checked={filters.draft}
            onChange={(e) => {
              set_filters({ ...filters, draft: e.target.checked });
            }}
          />
          <div>
            <b>Draft</b>
            <p style={{ marginTop: 5, fontSize: 13 }}>Show Draft Content</p>
          </div>
        </label>
      </div>
      <p className={classes.ProductionURLTitle}>Production URL:</p>
      <p style={{ wordWrap: "break-word", margin: 0 }}>
        {itemId ? `${window.location.origin}/${collection}/${itemId}` : `${window.location.origin}/${collection}`}
      </p>

      <p className={classes.ProductionURLTitle}>Cycle Speed</p>
      <p style={{ marginTop: 5, fontSize: 13 }}>
        Changing the Cycle Speed here doesn't affect the live version of the widgets. It's simply here to allow you to
        quickly go over all the content for a screen.
      </p>

      <button
        onClick={() => {
          set_selectedButton("fast");
          setCycleSpeed(2000);
        }}
        className={[classes.Button, selectedButton === "fast" && classes.ButtonActive].join(" ")}
        style={{ marginRight: 10 }}
      >
        Fast
      </button>
      <button
        onClick={() => {
          set_selectedButton("slow");
          setCycleSpeed(8000);
        }}
        className={[classes.Button, selectedButton === "slow" && classes.ButtonActive].join(" ")}
      >
        Slow
      </button>
    </div>
  );
}
