import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const StoreContext = React.createContext();

export function StoreProvider({ children }) {
  const [filters, set_filters] = useState({ published: true, draft: false });
  const [cycleSpeed, set_cycleSpeed] = useState(30000);
  const [uid] = useState(() => uuidv4());

  return (
    <StoreContext.Provider value={{ filters, set_filters, cycleSpeed, set_cycleSpeed, uid }}>
      {children}
    </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
