import classes from "./LargeMessage.module.scss";

import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";

export default function LargeMessage({
  id,
  title,
  text,
  image,
  large_color,
  cardDuration,
  animDuration,
  animate = true,
  showBg = true,
}) {
  const [isVisible, set_isVisible] = useState(showBg);

  useEffect(() => {
    if (!animate) return;

    const timeout = setTimeout(() => {
      set_isVisible(false);
    }, cardDuration - animDuration * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [animDuration, cardDuration, animate]);

  return (
    <div className={classes.LargeMessage}>
      {createPortal(
        <AnimatePresence>
          {isVisible && (
            <motion.div
              key={id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: animDuration }}
              className={classes.BG}
              style={{
                backgroundImage: `url('${
                  window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
                }/assets/${image?.id}')`,
              }}
            />
          )}
        </AnimatePresence>,
        document.getElementById("container")
      )}

      <div className={classes.Content} style={{ background: large_color }}>
        {title && <div className={classes.Title}>{title}</div>}
        {text && <div className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
    </div>
  );
}
