import classes from "./Logo.module.scss";

export default function Logo({ title, logo_black }) {
  return (
    <div className={classes.Logo}>
      <div className={classes.Title}>{title}</div>
      <img
        alt={title}
        className={classes.Image}
        src={`${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/assets/${logo_black?.id}`}
      />
    </div>
  );
}
