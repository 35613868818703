import classes from "./SocialStairs.module.scss";
import Message from "features/Screens/SocialStairs/Widgets/Message";
import Inspirational from "features/Screens/SocialStairs/Widgets/Inspirational";
import arrayShuffle from "array-shuffle";
import { useEffect, useState } from "react";
import img_city from "assets/city-skyline.png";
import Event from "features/Screens/SocialStairs/Widgets/Event";
import { useStore } from "utils/StoreProvider";
import useUrlParams from "utils/useUrlParams";

const MAX_PREVIEW_ITEMS = 4;

export default function SocialStairs({ screenData }) {
  const [items, set_items] = useState([]);
  const [currentItems, set_currentItems] = useState([]);
  const [index, set_index] = useState(0);
  const { cycleSpeed } = useStore();
  const { test } = useUrlParams();

  function setSlice() {
    let tmp_items = [];
    let tmp_index;
    if (!items.length) return;
    for (let i = 0; i < MAX_PREVIEW_ITEMS; i++) {
      tmp_index = (index + i) % items.length;
      if (items?.[tmp_index]) {
        tmp_items.push(items[tmp_index]);
      }
    }

    set_index(tmp_index);
    set_currentItems(tmp_items);
  }

  useEffect(() => {
    setSlice();
  }, [items]);

  useEffect(() => {
    if (!test) return;

    const timeout = setTimeout(setSlice, cycleSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [items, index, cycleSpeed]);

  useEffect(() => {
    let new_items = [];

    if (screenData?.messages?.length > 0) {
      new_items = new_items.concat(
        screenData?.messages?.map((message, messageIndex) => ({
          component: Message,
          data: { ...message, id: "message-" + messageIndex },
        }))
      );
    }

    if (screenData?.inspirational?.length > 0) {
      new_items = new_items.concat(
        screenData?.inspirational?.map((message, messageIndex) => ({
          component: Inspirational,
          data: { ...message, id: "inspirational-" + messageIndex },
        }))
      );
    }

    if (screenData?.events?.length > 0) {
      new_items = new_items.concat(
        screenData?.events?.map((event) => ({
          component: Event,
          data: { ...event, id: "event-" + event.id },
          size: 2,
        }))
      );
    }

    new_items = arrayShuffle(new_items);
    set_items(new_items);
  }, [screenData]);

  return (
    <Wrapper>
      {currentItems?.map((item) => (
        <item.component key={item.data.id} {...item.data} id={item.data.id} />
      ))}
    </Wrapper>
  );
}

function Wrapper({ children }) {
  const { test } = useUrlParams();

  return (
    <>
      {test && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url('${img_city}')`,
            backgroundSize: "cover",
            position: "absolute",
          }}
        />
      )}
      {test ? <div className={classes.Grid}>{children}</div> : children}
    </>
  );
}
