import ElevatorBanks from "features/Screens/ElevatorBanks/ElevatorBanks";
import ElevatorBanksLogos from "features/Screens/ElevatorBanksLogos/ElevatorBanksLogos";
import ElevatorCabs from "features/Screens/ElevatorCabs/ElevatorCabs";
import ElevatorCabsEvents from "features/Screens/ElevatorCabsEvents/ElevatorCabsEvents";
import ElevatorCabsLogos from "features/Screens/ElevatorCabsLogos/ElevatorCabsLogos";
import ElevatorCabsMessages from "features/Screens/ElevatorCabsMessages/ElevatorCabsMessages";
import ElevatorCabsPosters from "features/Screens/ElevatorCabsPosters/ElevatorCabsPosters";
import Lobby from "features/Screens/Lobby/Lobby";
import LobbyEvents from "features/Screens/LobbyEvents/LobbyEvents";
import LobbyMessages from "features/Screens/LobbyMessages/LobbyMessages";
import LobbyPosters from "features/Screens/LobbyPosters/LobbyPosters";
import SocialStairs from "features/Screens/SocialStairs/SocialStairs";
import SocialStairsEvents from "features/Screens/SocialStairsEvents/SocialStairsEvents";
import SocialStairsInspirational from "features/Screens/SocialStairsInspirational/SocialStairsInspirational";
import SocialStairsMessages from "features/Screens/SocialStairsMessages/SocialStairsMessages";
import SocialStairsPosters from "./SocialStairsPosters/SocialStairsPosters";

const ScreenConstants = {
  elevator_banks: {
    component: ElevatorBanks,
    width: 1792,
    height: 2496,
  },
  elevator_banks_tenant_logos: {
    component: ElevatorBanksLogos,
    width: 1792,
    height: 2496,
  },
  elevator_cabs: {
    component: ElevatorCabs,
    width: 2160,
    height: 3840,
  },
  elevator_cabs_events: {
    component: ElevatorCabsEvents,
    width: 2160,
    height: 3840,
  },
  elevator_cabs_tenant_logos: {
    component: ElevatorCabsLogos,
    width: 2160,
    height: 3840,
  },
  elevator_cabs_elevator_cabs_messages: {
    component: ElevatorCabsMessages,
    width: 2160,
    height: 3840,
  },
  elevator_cabs_elevator_cabs_posters: {
    component: ElevatorCabsPosters,
    width: 2160,
    height: 3840,
  },
  social_stairs: {
    component: SocialStairs,
    width: 3840,
    height: 2112,
  },
  social_stairs_events: {
    component: SocialStairsEvents,
    width: 3840,
    height: 2112,
  },
  social_stairs_social_stairs_messages: {
    component: SocialStairsMessages,
    width: 3840,
    height: 2112,
  },
  social_stairs_social_stairs_inspirational: {
    component: SocialStairsInspirational,
    width: 3840,
    height: 2112,
  },
  social_stairs_social_stairs_posters: {
    component: SocialStairsPosters,
    width: 3840,
    height: 2112,
  },
  lobby: {
    component: Lobby,
    width: 768,
    height: 1152,
  },
  lobby_events: {
    component: LobbyEvents,
    width: 768,
    height: 1152,
  },
  lobby_lobby_posters: {
    component: LobbyPosters,
    width: 768,
    height: 1152,
  },
  lobby_lobby_messages: {
    component: LobbyMessages,
    width: 768,
    height: 1152,
  },
};

export default ScreenConstants;
