import { useMqtt } from "utils/MqttProvider";
import { useEffect, useState } from "react";

export default function useWidgetData({ collection, itemId, draft = false }) {
  const { subscribe, unsubscribe } = useMqtt();
  const [data, set_data] = useState(null);

  useEffect(() => {
    const subId = subscribe(`pn1/data-center/${draft ? "draft" : "feed"}/${collection}`, (topic, message) => {
      const mqttData = JSON.parse(message);
      if (mqttData && mqttData.find && itemId) {
        set_data(mqttData.find((item) => item.id === itemId));
      } else {
        set_data(mqttData);
      }
    });

    return () => {
      set_data(null);
      unsubscribe(subId);
    };
  }, [collection, itemId, draft, subscribe, unsubscribe]);

  return data;
}
