import classes from "./Event.module.scss";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

export default function Event({ datetime, location, title, cardDuration, animDuration }) {
  const [isVisible, set_isVisible] = useState(false);

  useEffect(() => {
    const timeoutAppear = setTimeout(() => {
      set_isVisible(true);
    }, animDuration * 1000 - 300);

    // const timeoutDisappear = setTimeout(() => {
    //   set_isVisible(false);
    // }, cardDuration - animDuration * 1000 + 300);

    return () => {
      clearTimeout(timeoutAppear);
      // clearTimeout(timeoutDisappear);
    };
  }, [animDuration, cardDuration]);

  return (
    <div className={classes.Event}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "-100vh" }}
            transition={{ duration: animDuration }}
            style={{ width: "100%" }}
          >
            <div className={classes.Intro}>
              <div className={classes.IntroSubtitle}>Currently at</div>
              <div className={classes.IntroTitle}>PENN 1</div>
            </div>
            <div className={classes.Card}>
              <div className={classes.Fulldate}>
                {dayjs(datetime).format("dddd")} {dayjs(datetime).format("MMM")}. {dayjs(datetime).format("D")}
              </div>
              <div className={classes.Content}>
                <div className={classes.Title}>{title}</div>
                <div className={classes.TimeLocation}>
                  {dayjs(datetime).format("ha")}<span className={classes.Location}>{location}</span>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
