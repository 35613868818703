import { useParams } from "react-router-dom";
import LargeMessage from "features/Screens/ElevatorCabs/Widgets/LargeMessage";
import Message from "features/Screens/ElevatorCabs/Widgets/Message";
import useItemData from "utils/useItemData";

export default function ElevatorCabsMessage() {
  const { collection, itemId } = useParams();

  const itemData = useItemData({ collection, itemId });

  const Component = itemData?.large ? LargeMessage : Message;
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div id="container" style={{ position: "absolute", height: "100%", width: "100%", zIndex: 1 }} />
      <Component {...itemData} animate={false} />
    </div>
  );
}
