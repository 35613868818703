import { useEffect, useRef, useState } from "react";
import classes from "./FitToParent.module.scss";

const BORDER_SIZE = 3;

export default function FitToParent({ children, width, height }) {
  const [scale, set_scale] = useState(1);
  const viewportAreaRef = useRef();

  useEffect(() => {
    if (!viewportAreaRef.current) return;

    function handleResize() {
      const scaleX = (viewportAreaRef.current.clientWidth - BORDER_SIZE * 2) / width;
      const scaleY = (viewportAreaRef.current.clientHeight - BORDER_SIZE * 2) / height;
      const scale = Math.min(scaleX, scaleY);

      set_scale(scale);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height, viewportAreaRef]);

  return (
    <div className={classes.ViewportArea} ref={viewportAreaRef}>
      <div
        className={classes.ContentSizer}
        style={{
          width: scale * width,
          height: scale * height,
          borderWidth: BORDER_SIZE,
          overflow: "hidden",
        }}
      >
        <div className={classes.ContentScaler} style={{ width, height, transform: `scale(${scale})` }}>
          {children}
        </div>
      </div>
    </div>
  );
}
