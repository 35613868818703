import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import classes from "./Message.module.scss";

export default function Message({ title, text, image, showBg = true, animDuration, cardDuration }) {
  const [showBlackFade, set_showBlackFade] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      set_showBlackFade(true);
    }, cardDuration - animDuration * 1000 + 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [animDuration, cardDuration]);

  return (
    <div className={classes.Message}>
      {image?.type?.indexOf("image/") === 0 && (
        <div
          className={classes.ImageBg}
          style={{
            backgroundImage: `url('${
              window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
            }/assets/${image?.id}')`,
          }}
        />
      )}
      {image?.type?.indexOf("video/") === 0 && (
        <div className={classes.VideoWrapper}>
          {showBg && (
            <video
              className={classes.Video}
              src={`${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/assets/${image?.id}`}
              muted
              autoPlay
              loop
            />
          )}
          <AnimatePresence>
            {showBlackFade && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: animDuration }}
                style={{
                  position: "absolute",
                  background: "black",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            )}
          </AnimatePresence>
        </div>
      )}
      <div className={classes.Content}>
        {title && <div className={classes.Title}>{title}</div>}
        {text && <div className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
    </div>
  );
}
