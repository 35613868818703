import classes from "./Poster.module.scss";

export default function Poster({ image }) {
  console.log(image);
  return (
    <div className={classes.Poster}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          backgroundImage: `url('${
            window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
          }/assets/${image?.id}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
}
