import React, { useContext, useEffect } from "react";
import mqtt from "mqtt";
import mqttMatch from "mqtt-match";
import { v4 as uuidv4 } from "uuid";

const MqttContext = React.createContext();

export function MqttProvider({ mqttConfig, children }) {
  let clientPromiseInstance;
  let subscriptions = [];

  if (!mqttConfig) throw Error("MqttProvider must have a `mqttConfig` prop");
  if (!mqttConfig.url) throw Error("mqttConfig must have a `url` property");

  const handleMessage = (topic, message) => {
    subscriptions
      .filter((subscription) => mqttMatch(subscription.topic, topic))
      .forEach((subscription) => {
        subscription.callback(topic, message.toString());
      });
  };

  const clientPromise = new Promise((resolve, reject) => {
    const client = mqtt.connect(mqttConfig.url, mqttConfig.options);

    client.on("connect", () => {
      resolve(client);
    });

    client.on("error", (connectionError) => {
      reject(connectionError);
    });

    client.on("message", handleMessage);
  });

  const getClient = () => {
    if (!clientPromiseInstance) {
      clientPromiseInstance = clientPromise;
    }

    return clientPromiseInstance;
  };

  async function subscribe(topic, callback) {
    let client = await getClient();
    client.subscribe(topic);

    // Generate a unique key to easily unsubscribe later when needed
    let key;
    let keyIsUnique = false;
    while (!keyIsUnique) {
      key = uuidv4();
      if (!subscriptions.find((subscription) => subscription.key === key)) {
        keyIsUnique = true;
      }
    }

    subscriptions.push({ key, topic, callback });

    // Return key so devs can unsubscribe when needed
    return key;
  }

  async function unsubscribe(key) {
    // Delete subscription from array
    const subscription = subscriptions.splice(
      subscriptions.findIndex((subscription) => subscription.key === key),
      1
    )[0];

    // We have to check that subscription is not null because if a component re-renders before the client resolves,
    // it will try to unsubscribe in its cleanup effect but the subscription will not have occured
    if (!subscription) return;

    // Remove client subscription to topic if this was the last subscription listening to that topic
    const { topic } = subscription;
    let countSubscriptionsWithTopic = subscriptions.filter((subscription) => subscription.topic === topic).length;
    if (countSubscriptionsWithTopic === 0) {
      let client = await getClient();
      client.unsubscribe(topic);
    }
  }

  async function publish(topic, message) {
    let client = await getClient();
    client.publish(topic, message);
  }

  useEffect(() => {
    getClient();
  }, []);

  return (
    <MqttContext.Provider
      value={{
        subscribe,
        unsubscribe,
        publish,
      }}
    >
      {children}
    </MqttContext.Provider>
  );
}

export function useMqtt() {
  return useContext(MqttContext);
}
