import classes from "./ElevatorBanks.module.scss";
// import Logo from "features/Widgets/Logo";
// import CardCycler from "features/CardCycler";
import arrayShuffle from "array-shuffle";
import { useEffect, useState } from "react";
import img_tree from "assets/elevator-banks.jpg";
import Logo from "./Widgets/Logo";
import Cycler from "./Cycler";
import { useLocation } from "react-router";
import ScreenConstants from "../ScreenConstants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ElevatorBanks({ screenData, news, did_you_know }) {
  const [items, set_items] = useState([]);
  const query = useQuery();

  useEffect(() => {
    let new_items = [];

    if (screenData?.logos?.length > 0) {
      new_items = new_items.concat(
        screenData?.logos?.map((logo, logoIndex) => ({
          component: Logo,
          data: { ...logo, id: "logo-" + logoIndex + "-" + logo?.id },
          size: 3,
        }))
      );
    }

    new_items = arrayShuffle(new_items);
    set_items(new_items);
  }, [screenData]);

  return (
    <>
      {query.get("test") === "1" && <div className={classes.BG} style={{ backgroundImage: `url('${img_tree}')` }} />}
      <div
        className={classes.Cards}
        style={{ width: ScreenConstants.elevator_banks.width, height: ScreenConstants.elevator_banks.height }}
      >
        <Cycler items={items} />
      </div>
    </>
  );
}
