import useWidgetData from "utils/useWidgetData";
import { useParams } from "react-router";
import ScreenConstants from "features/Screens/ScreenConstants";
import { useEffect, useState } from "react";
import deepMerge from "utils/deepMerge";
import useUrlParams from "utils/useUrlParams";

export default function Screen() {
  // Fetch screen
  const { screen, collection, itemId } = useParams();
  const [screenData, set_screenData] = useState();

  const { filters, test } = useUrlParams();

  const screenData_published = useWidgetData({ collection: screen || collection, itemId });
  const screenData_draft = useWidgetData({ collection: screen || collection, itemId, draft: true });

  useEffect(() => {
    if (test) {
      if (filters?.includes("published") && filters?.includes("draft")) {
        set_screenData(deepMerge(screenData_draft, screenData_published));
      } else if (filters?.includes("published")) {
        set_screenData(screenData_published);
      } else if (filters?.includes("draft")) {
        set_screenData(screenData_draft);
      }
    } else {
      // Default to published items
      set_screenData(screenData_published);
    }
  }, [screenData_draft, screenData_published, filters, test]);

  const ScreenConfig = screen ? ScreenConstants?.[screen + "_" + collection] : ScreenConstants?.[collection];
  const ScreenComponent = ScreenConfig?.component;

  return (
    <div style={{ width: "100%", height: "100vh", overflowY: "hidden", position: "relative" }}>
      {ScreenComponent ? <ScreenComponent screenData={screenData} /> : <h1>TODO</h1>}
    </div>
  );
}
