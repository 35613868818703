import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import classes from "./Message.module.scss";
import random from "lodash.random";

const BG = {
  yellow: {
    color1: "#FFC16C",
    color2: "#FFE5BE",
    color3: "#FFA530",
  },
  orange: {
    color1: "#FC9560",
    color2: "#FFD0B4",
    color3: "#F7652C",
  },
  green: {
    color1: "#64A396",
    color2: "#008772",
    color3: "#BCD6CF",
  },
  purple: {
    color1: "#9A5190",
    color2: "#DCC7DD",
    color3: "#B782B1",
  },
  red: {
    color1: "#F7652C",
    color2: "#F2312A",
    color3: "#F9795A",
  },
  blue: {
    color1: "#6E9FC5",
    color2: "#005799",
    color3: "#9CBED7",
  },
  black: {
    color1: "#1b1b1b",
    color2: "#201C1D",
    color3: "#58595B",
  },
};

export default function Message({ title, background_color, text, image, animDuration, cardDuration, animate = true }) {
  const [isVisible, set_isVisible] = useState(true);
  const [default_bg] = useState(() => BG[Object.keys(BG)[random(0, BG.length - 1)]]);
  const bg = BG?.[background_color] || default_bg;

  useEffect(() => {
    if (!animate) return;

    const timeout = setTimeout(() => {
      set_isVisible(false);
    }, cardDuration - animDuration * 1000 + 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [animDuration, cardDuration]);

  return (
    <div className={classes.Message}>
      {image ? (
        <>
          {image?.type?.indexOf("image/") === 0 && (
            <div
              className={classes.Image}
              style={{
                backgroundImage: `url('${
                  window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
                }/assets/${image?.id}')`,
                backgroundColor: bg.color1,
              }}
            />
          )}
          {image?.type?.indexOf("video/") === 0 && (
            <div className={classes.VideoWrapper}>
              <video
                className={classes.Video}
                src={`${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/assets/${
                  image?.id
                }`}
                muted
                autoPlay
                loop
              />
              {/* <AnimatePresence>
                {showBlackFade && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: animDuration }}
                    style={{
                      position: "absolute",
                      background: "black",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  />
                )}
              </AnimatePresence> */}
            </div>
          )}
        </>
      ) : (
        <div className={classes.TopAccent} style={{ backgroundColor: bg.color1 }} />
      )}
      <div className={classes.Content}>
        <div className={classes.ContentText} style={{ backgroundColor: bg.color2 }}>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                initial={{ x: "100vw" }}
                animate={{ x: 0 }}
                exit={{ x: "-100vw" }}
                transition={{ duration: animDuration }}
              >
                <div className={classes.Title}>{title}</div>
                <div className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className={classes.ContentAccent} style={{ backgroundColor: bg.color3 }} />
      </div>
    </div>
  );
}
