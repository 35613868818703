import classes from "./Inspirational.module.scss";
import svg_circles from "assets/circles.svg";
import svg_clouds from "assets/clouds.svg";
import svg_stars from "assets/stars.svg";
import svg_squares from "assets/squares.svg";
import { useState } from "react";
import { getRandomInt, useRandomColor } from "../utils";

const shapes = [
  { svg: svg_circles, className: classes.Circles },
  { svg: svg_clouds, className: classes.Clouds },
  { svg: svg_stars, className: classes.Stars },
  { svg: svg_squares, className: classes.Squares },
];

export default function Inspirational({ text }) {
  const [random_shape] = useState(() => getRandomInt(4));
  const shape = shapes[random_shape];
  const backgroundColor = useRandomColor();

  return (
    <div className={classes.Inspirational} style={{ backgroundColor }}>
      <div className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />
      <img alt={text} src={shape.svg} className={shape.className} />
    </div>
  );
}
