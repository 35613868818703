import classes from "./Event.module.scss";
import dayjs from "dayjs";
import { useRandomColor } from "../utils";

export default function Event({ datetime, location, title, text, image }) {
  const backgroundColor = useRandomColor();

  return (
    <div
      className={classes.Event}
      style={{
        backgroundColor,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          backgroundImage: `url('${
            window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
          }/assets/${image?.id}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div style={{ position: "relative", zIndex: 3 }}>
        <div className={classes.Intro}>
          <div className={classes.IntroSubtitle}>Currently at</div>
          <div className={classes.IntroTitle}>PENN 1</div>
        </div>
        <div className={classes.Calendar}>
          <div className={classes.Fulldate}>
            <div className={classes.Weekday}>{dayjs(datetime).format("ddd")}</div>
            <div className={classes.Date}>{dayjs(datetime).format("D")}</div>
            <div className={classes.Month}>{dayjs(datetime).format("MMM")}</div>
          </div>
          <div className={classes.Content}>
            <div className={classes.Title}>{title}</div>
            <div className={classes.Text}>{text}</div>
            <div className={classes.TimeLocation}>
              <span className={classes.Time}>{dayjs(datetime).format("ha")}</span> {location}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
