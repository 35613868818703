import { useParams } from "react-router-dom";
import Poster from "features/Screens/SocialStairs/Widgets/Poster";
import useItemData from "utils/useItemData";
import useUrlParams from "utils/useUrlParams";
import { useEffect } from "react";
import { useMqtt } from "utils/MqttProvider";

export default function SocialStairsPoster() {
  const { collection, itemId } = useParams();
  const { publish } = useMqtt();
  const { test } = useUrlParams();

  const itemData = useItemData({ collection, itemId });

  useEffect(() => {
    if (test || !itemData) return;
    publish(`pn1/hub-community-unity/loadStatuses/social_stairs/social_stairs_posters/${itemId}`, JSON.stringify(true));
  }, [test, itemData]);

  return <Poster {...itemData} image={itemData?.image} animate={false} />;
}
