import { useEffect, useRef, useState } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { createPortal } from "react-dom";
import { useStore } from "utils/StoreProvider";
import ScreenConstants from "../ScreenConstants";

const CARD_TRANSITION_DURATION = 15;
const BG_TRANSITION_DURATION = 3;
const GAP = 80;

const BG_CYCLE = [
  {
    color1: "#FFC16C",
    color2: "#FFE5BE",
    color3: "#FFA530",
    height: 1 / 3,
  },
  {
    color1: "#FC9560",
    color2: "#FFD0B4",
    color3: "#F7652C",
    height: 2 / 3,
  },
  {
    color1: "#64A396",
    color2: "#008772",
    color3: "#BCD6CF",
    height: 1 / 2,
  },
];

function get_slice(items, index, getNext = true) {
  const MAX_ITEMS = 3;
  const MAX_SIZE = 4;

  if (!items) return null;

  let current = [];
  let next;
  let wrappedIndex;
  let size = 0;

  for (let i = 0; i < Math.min(MAX_ITEMS, items.length); i++) {
    wrappedIndex = (index + i) % items.length;

    // Limit to MAX_SIZE
    if (size + items[wrappedIndex].size >= MAX_SIZE) {
      break;
    }

    current.push(items[wrappedIndex]);
    size += items[wrappedIndex].size;
  }

  const nextIndex = wrappedIndex;
  if (getNext) {
    next = get_slice(items, nextIndex, false).current;
  }

  return { current, next, nextIndex };
}

function get_final_slice(slice, past_slice) {
  const past =
    past_slice?.current?.filter(
      (pastItem) => !slice.current.find((sliceItem) => sliceItem.data.id === pastItem.data.id)
    ) || [];

  return {
    ...slice,
    past,
  };
}

function BGCycler() {
  const [bgIndex, set_bgIndex] = useState(0);
  const { cycleSpeed } = useStore();

  useEffect(() => {
    const timeout = setTimeout(() => {
      set_bgIndex((bgIndex + 1) % BG_CYCLE.length);
    }, cycleSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [bgIndex, cycleSpeed]);

  return createPortal(
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <motion.div
        animate={{
          backgroundColor: BG_CYCLE[bgIndex].color3,
          height: 100 * BG_CYCLE[bgIndex].height + "%",
        }}
        initial={false}
        transition={{ duration: BG_TRANSITION_DURATION }}
        style={{ position: "relative" }}
      >
        <AnimatePresence>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
              backgroundColor: BG_CYCLE[bgIndex].color2,
            }}
            exit={{
              backgroundColor: BG_CYCLE[(bgIndex + 1) % BG_CYCLE.length].color3,
              transition: {
                duration: BG_TRANSITION_DURATION,
                delay: 0,
              },
            }}
            key={bgIndex}
            transition={{ duration: BG_TRANSITION_DURATION, delay: cycleSpeed / 1000 - BG_TRANSITION_DURATION }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </AnimatePresence>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          backgroundColor: BG_CYCLE[bgIndex].color1,
          height: 100 * (1 - BG_CYCLE[bgIndex].height) + "%",
        }}
        transition={{ duration: BG_TRANSITION_DURATION }}
      />
    </div>,
    document.getElementById("container")
  );
}

export default function Cycler({ items }) {
  const [index, set_index] = useState(0);
  const [card_sublist, set_card_sublist] = useState(() => get_slice(items, 0));
  const pastIndex = useRef(0);
  const [pastHeight, set_pastHeight] = useState(0);
  const { cycleSpeed } = useStore();
  const { width } = ScreenConstants.elevator_cabs;

  useEffect(() => {
    if (!items?.length) return;

    const slice = get_slice(items, index);
    const past_slice = get_slice(items, pastIndex.current, false);
    const final_slice = get_final_slice(slice, past_slice);
    set_card_sublist(final_slice);

    const timeout = setTimeout(() => {
      pastIndex.current = index;
      set_index(slice.nextIndex % items.length);
    }, cycleSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [items, index, pastIndex, cycleSpeed]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <BGCycler />
      <AnimateSharedLayout>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "auto",
            position: "relative",
            gap: GAP,
            transform: `translate3d(0, -${(pastHeight + GAP) / 2}px, 0)`,
            width,
          }}
        >
          <motion.div
            style={{
              display: "grid",
              gridTemplateRows: "auto",
              gap: GAP,
              width,
            }}
            initial={{ y: 0 }}
            animate={{ y: -3840 }}
            transition={{ duration: CARD_TRANSITION_DURATION }}
            key={card_sublist?.past?.map((card) => card.data.id).join("_")}
            ref={(e) => {
              if (e?.clientHeight) {
                set_pastHeight(e?.clientHeight);
              }
            }}
          >
            {card_sublist?.past?.map((card) => (
              <motion.div
                key={card.data.id}
                layoutId={card.data.id}
                transition={{ duration: CARD_TRANSITION_DURATION }}
              >
                <card.component
                  {...card.data}
                  cardDuration={cycleSpeed}
                  animDuration={CARD_TRANSITION_DURATION}
                  showBg={false}
                />
              </motion.div>
            ))}
          </motion.div>

          {card_sublist &&
            card_sublist.current.map((card) => (
              <motion.div
                key={card.data.id}
                layoutId={card.data.id}
                initial={{ y: 3840 }}
                animate={{ y: 0 }}
                transition={{ duration: CARD_TRANSITION_DURATION }}
              >
                <card.component {...card.data} cardDuration={cycleSpeed} animDuration={CARD_TRANSITION_DURATION} />
              </motion.div>
            ))}

          {/* Preload assets in next items */}
          {card_sublist?.next &&
            card_sublist?.next?.map((card) => (
              <div key={card.data.id} style={{ display: "none" }}>
                <card.component {...card.data} cardDuration={cycleSpeed} animDuration={CARD_TRANSITION_DURATION} />
              </div>
            ))}
        </div>
      </AnimateSharedLayout>
    </div>
  );
}
