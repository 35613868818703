import { useParams } from "react-router-dom";
import Message from "features/Screens/Lobby/Widgets/Message";
import useItemData from "utils/useItemData";

export default function LobbyMessage() {
  const { collection, itemId } = useParams();

  const itemData = useItemData({ collection, itemId });

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Message {...itemData} image={ itemData?.image } animate={false} />
    </div>
  );
}
