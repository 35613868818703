import { useParams } from "react-router-dom";
import Event from "features/Screens/Lobby/Widgets/Event";
import useItemData from "utils/useItemData";

export default function LobbyEvents() {
  const { collection, itemId } = useParams();

  const itemData = useItemData({ collection, itemId });

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Event {...itemData} image={ itemData?.image } animate={false} />
    </div>
  );
}
