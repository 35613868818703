import mergewith from "lodash.mergewith";

function customizer(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export default function deepMerge(...args) {
  return mergewith(...args, customizer);
}
