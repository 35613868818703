import classes from "./Nav.module.scss";
import { NavLink } from "react-router-dom";
import { useMqtt } from "utils/MqttProvider";
import { useEffect, useState } from "react";

export default function Nav() {
  const { subscribe, unsubscribe } = useMqtt();
  const [elevator_banks, set_elevator_banks] = useState([]);
  const [elevator_cabs, set_elevator_cabs] = useState([]);

  useEffect(() => {
    const subId = subscribe("pn1/data-center/feed/elevator_cabs", (topic, message) => {
      const data = JSON.parse(message);
      set_elevator_cabs(data.map((item) => ({ id: item.id, title: item.internal_name })));
    });

    return () => {
      unsubscribe(subId);
    };
  }, [subscribe, unsubscribe]);

  useEffect(() => {
    const subId = subscribe("pn1/data-center/feed/elevator_banks", (topic, message) => {
      const data = JSON.parse(message);
      set_elevator_banks(data.map((item) => ({ id: item.id, title: item.internal_name })));
    });

    return () => {
      unsubscribe(subId);
    };
  }, [subscribe, unsubscribe]);

  return (
    <div className={classes.Nav}>
      <b style={{ display: "block", fontSize: 18 }}>Preview</b>
      <ul>
        <li style={{ marginTop: 20 }}>
          <NavLink
            to={`/preview/elevator_banks/${elevator_banks?.[0]?.id}`}
            style={{ fontWeight: "bold", fontSize: 16, display: "flex", alignItems: "center" }}
          >
            <span className="material-icons" style={{ marginRight: 10 }}>
              aspect_ratio
            </span>
            Elevator Banks
          </NavLink>
          <ul style={{ listStyle: "none", marginLeft: 14 }}>
            {elevator_banks.map((item) => (
              <li key={item.id}>
                <NavLink
                  activeStyle={{ color: "#3d83d9" }}
                  style={{ display: "inline-block" }}
                  to={`/preview/elevator_banks/${item.id}`}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
        <li style={{ marginTop: 20 }}>
          <NavLink
            to={`/preview/elevator_cabs/${elevator_cabs?.[0]?.id}`}
            style={{ fontWeight: "bold", fontSize: 16, display: "flex", alignItems: "center" }}
          >
            <span className="material-icons" style={{ marginRight: 10 }}>
              elevator
            </span>
            Elevator Cabs
          </NavLink>
          <ul style={{ listStyle: "none", marginLeft: 14 }}>
            {elevator_cabs.map((item) => (
              <li key={item.id}>
                <NavLink
                  activeStyle={{ color: "#3d83d9" }}
                  style={{ display: "inline-block" }}
                  to={`/preview/elevator_cabs/${item.id}`}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
        <li style={{ marginTop: 20 }}>
          <NavLink activeStyle={{ color: "#3d83d9" }} style={{ display: "inline-block" }} to="/preview/social_stairs">
            <div style={{ fontWeight: "bold", fontSize: 16, display: "flex", alignItems: "center" }}>
              <span className="material-icons" style={{ marginRight: 10 }}>
                stairs
              </span>
              Social Stairs
            </div>
          </NavLink>
        </li>
        <li style={{ marginTop: 20 }}>
          <NavLink activeStyle={{ color: "#3d83d9" }} style={{ display: "inline-block" }} to="/preview/lobby">
            <div style={{ fontWeight: "bold", fontSize: 16, display: "flex", alignItems: "center" }}>
              <span className="material-icons" style={{ marginRight: 10 }}>
                sensor_door
              </span>
              Lobby
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
