import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useStore } from "utils/StoreProvider";

const ANIMATION_DURATION = 0.85;

export default function Cycler({ items }) {
  const [index, set_index] = useState(0);
  const { cycleSpeed } = useStore();

  useEffect(() => {
    if (!items?.length) return;

    const timeout = setTimeout(() => {
      set_index((index) => (index + 1) % items.length);
    }, cycleSpeed);

    return () => {
      clearTimeout(timeout);
    };
  }, [items, cycleSpeed, index]);

  const Widget = items[index].component;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "-100%" }}
        key={index}
        transition={{ duration: ANIMATION_DURATION }}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      >
        <Widget {...items?.[index]?.data} cardDuration={cycleSpeed} animDuration={ANIMATION_DURATION} />
      </motion.div>
    </AnimatePresence>
  );
}
