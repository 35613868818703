import { useParams } from "react-router-dom";
import Message from "features/Screens/SocialStairs/Widgets/Message";
import useItemData from "utils/useItemData";
import useUrlParams from "utils/useUrlParams";
import { useEffect } from "react";
import { useMqtt } from "utils/MqttProvider";

export default function SocialStairsMessage() {
  const { collection, itemId } = useParams();
  const { publish } = useMqtt();
  const { test } = useUrlParams();

  const itemData = useItemData({ collection, itemId });

  useEffect(() => {
    if (test || !itemData) return;
    publish(
      `pn1/hub-community-unity/loadStatuses/social_stairs/social_stairs_messages/${itemId}`,
      JSON.stringify(true)
    );
  }, [test, itemData]);

  return (
    <Wrapper>
      <Message {...itemData} image={itemData?.image} animate={false} />
    </Wrapper>
  );
}

function Wrapper({ children }) {
  const { test } = useUrlParams();

  return test ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gridTemplateRows: "repeat(3,1fr)",
      }}
    >
      {children}
    </div>
  ) : (
    children
  );
}
