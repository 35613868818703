import classes from "./Event.module.scss";
import dayjs from "dayjs";

export default function Event({ datetime, location, title, cardDuration, animDuration }) {
  return (
    <div className={classes.Event}>
      <div className={classes.Intro}>
        <div className={classes.IntroSubtitle}>Currently at</div>
        <div className={classes.IntroTitle}>PENN 1</div>
      </div>
      <div className={classes.Card}>
        <div className={classes.Fulldate}>
          {dayjs(datetime).format("dddd")} {dayjs(datetime).format("MMM")}. {dayjs(datetime).format("D")}
        </div>
        <div className={classes.Content}>
          <div className={classes.Title}>{title}</div>
          <div className={classes.TimeLocation}>
            {dayjs(datetime).format("ha")}<span className={classes.Location}>{location}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
