import FitToParent from "./FitToParent";
import ScreenConstants from "features/Screens/ScreenConstants";
import { useParams } from "react-router";
import { useStore } from "utils/StoreProvider";

const generateURLParams = ({ filters, uid }) => {
  let urlParams = new URLSearchParams();
  urlParams.append("test", 1);
  if (filters.published) {
    urlParams.append("filters", "published");
  }
  if (filters.draft) {
    urlParams.append("filters", "draft");
  }

  urlParams.append("uid", uid);

  return urlParams.toString();
};

export default function Iframe() {
  const { screen, collection, itemId } = useParams();
  const { filters, uid } = useStore();

  const screenContant = screen ? ScreenConstants?.[screen] : ScreenConstants?.[collection];

  return (
    <div style={{ width: "100%", height: "100vh", overflowY: "hidden", background: "rgb(38, 50, 56)" }}>
      <FitToParent width={screenContant.width} height={screenContant.height}>
        <iframe
          title={collection}
          src={
            screen
              ? `${window.location.origin}/${screen}/${collection}/${itemId}?${generateURLParams({ filters, uid })}`
              : itemId
              ? `${window.location.origin}/${collection}/${itemId}?${generateURLParams({ filters, uid })}`
              : `${window.location.origin}/${collection}?${generateURLParams({ filters, uid })}`
          }
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </FitToParent>
    </div>
  );
}
