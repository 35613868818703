import classes from "./Poster.module.scss";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export default function Poster({ title, image, animDuration, cardDuration }) {
  const [isVisible, set_isVisible] = useState(false);

  useEffect(() => {
    const timeoutAppear = setTimeout(() => {
      set_isVisible(true);
    }, animDuration * 1000 - 300);

    return () => {
      clearTimeout(timeoutAppear);
    };
  }, [animDuration, cardDuration]);

  return (
    <div className={classes.Poster}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ duration: animDuration }}
            className={classes.Layout}
          >
            <img
              alt={title}
              className={classes.Image}
              src={`${window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL}/assets/${image?.id}`}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
