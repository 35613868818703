import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Screen from "features/Screen";
import classes from "./App.module.scss";
import Iframe from "features/Iframe";
import Home from "features/Home";
import Nav from "features/Nav";
import Filters from "features/Filters";
import CycleSpeedSub from "utils/CycleSpeedSub";

function App() {
  return (
    <div>
      <CycleSpeedSub />
      <Router>
        <div className={classes.AppLayout}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            {/* TEST ROUTES */}
            <Route path="/preview/:screen/:collection/:itemId">
              <div style={{ display: "flex", width: "100%", justifyContent: "stretch" }}>
                <Nav />
                <Iframe />
              </div>
            </Route>

            <Route path="/preview/:collection/:itemId">
              <div style={{ display: "flex", width: "100%", justifyContent: "stretch" }}>
                <Nav />
                <Iframe />
                <Filters />
              </div>
            </Route>

            <Route path="/preview/:collection">
              <div style={{ display: "flex", width: "100%", justifyContent: "stretch" }}>
                <Nav />
                <Iframe />
                <Filters />
              </div>
            </Route>

            {/* NORMAL ROUTES */}
            <Route path="/:screen/:collection/:itemId" render={({ location }) => <Screen key={location.pathname} />} />
            <Route path="/:collection/:itemId" render={({ location }) => <Screen key={location.pathname} />} />
            <Route path="/:collection" render={({ location }) => <Screen key={location.pathname} />} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
