import { useParams } from "react-router-dom";
import Logo from "features/Screens/ElevatorBanks/Widgets/Logo";
import useItemData from "utils/useItemData";

export default function ElevatorBanksLogo() {
  const { collection, itemId } = useParams();

  const itemData = useItemData({ collection, itemId });

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Logo {...itemData} logo_white={{ id: itemData?.logo_white }}  animate={false} />
    </div>
  );
}
