import { useRandomColor } from "../utils";
import classes from "./Message.module.scss";

export default function Message({ title, text, image }) {
  const backgroundColor = useRandomColor();

  return (
    <div
      className={classes.Message}
      style={{
        backgroundColor,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          backgroundImage: `url('${
            window?._env_?.REACT_APP_DIRECTUS_URL || process.env.REACT_APP_DIRECTUS_URL
          }/assets/${image?.id}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div style={{ position: "relative", zIndex: 3 }}>
        <div className={classes.Title}>{title}</div>
        <div className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
}
